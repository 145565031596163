import React, { Fragment, useEffect, useState } from 'react'
import icon from '../../assets/icon.svg';
import success from '../../assets/success.png';
import footerbottom from '../../assets/footer-bottom.png'
import { IoLocationOutline, IoMailOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Footer from '../Footer';

function ContactUs({ isNavVisisble, setIsNavVisible }) {
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsOpen(false)
            }, 5000);
        }
    }, [isOpen])

    return (
        <div className="worldswebuild bg-[length:100vw_100vh] bg-no-repeat bg-bermuda  h-[100vh] flex flex-col">
            <div className="h-24 w-20  mx-5 bg-black  text-white z-10 fixed top-0 left-8 flex flex-col  ">
                <div className='flex-1 flex items-center justify-center'>
                    <Link to="/">   <img src='/Illustrix_Icon.png' /></Link>
                </div>
                <div className='flex items-center justify-center font-Krona'>
                    Illustrix
                </div>
            </div>
            <div className='bg-red fixed right-6 top-3 text-red-700'>
                <img className='hover:cursor-pointer' src={icon} onClick={() => { setIsNavVisible(true) }} />
            </div>
            <div className='flex w-full h-[70%]'>
                <div className='flex-1 flex w-full h-full m-auto text-white text-8xl font-Krona items-center align-middle justify-center text-left'>
                    Touch <br />Base
                </div>
                <div className='flex-[2] flex items-center justify-center mt-10'>
                    <form className='grid grid-cols-2 gap-5 w-[80%]'>

                        <div id="border" class=" input-border">
                            <input type="text" id="text" name="name" class="text" required />
                            <label className="label">Full Name</label>
                            <div class="border"></div>
                        </div>
                        <div id="border" class=" input-border">
                            <input type="text" id="text" name="name" class="text" required />
                            <label className="label">Email</label>
                            <div class="border"></div>
                        </div>

                        <div id="border" class="col-span-2 input-border">
                            <input type="text" id="text" name="name" class="text" required />
                            <label className="label">Message Subject</label>
                            <div class="border"></div>
                        </div>
                        <div className='col-span-2'>
                            <textarea placeholder="Message" rows={7} type="text" name="name" className="placeholder-shown:text-zinc-600 p-2 ml-4 mr-10 mt-4 w-full" style={{ color: "white", background: "#161616", resize: "none" }} />
                        </div>
                        <div className="col-span-2 flex items-end justify-end">
                            <button onClick={() => { setIsOpen(!isOpen) }} className={'border-2 z-[60 bg-blackcolor text-pinkshade font-Krona text-sm w-28 h-10 border-white rounded-lg'}
                            >Submit</button>
                        </div>
                        {
                            isOpen ? <div className='z-[100] overflow-y-auto flex bg-slate-100 bg-opacity-20 overflow-x-hidden absolute top-40 right-40 left-40 md:inset-0 h-modal md:h-full items-center justify-center w-full h-full'>
                                <div className="w-[30%] h-[40%] p-6 bg-black rounded-lg items-center flex text-center font-Krona text-pinkshade justify-center text-lg">
                                    <div className='items-center text-center justify-center flex-row'>
                                        <center>   <img src={success} style={{ width: "40%" }} /></center>
                                        <div className='mt-4'>   Message Sent!<br />We reach back to you shortly</div>
                                    </div>
                                </div>
                            </div> : <></>
                        }
                        {/* <div className="z-[100] relative rounded bg-white w-28 h-10">
                                <div className="z-50 bg-white rounded-lg w-full h-full border-white">
                                </div>
                                <button className={'border-2 z-[60] focus:outline-none bg-black text- font-Krona text-sm absolute top-0 transform -translate-x-2.5 -translate-y-2.5 left-0 w-full h-full border-black rounded'}
                                ><span class="flex"> &nbsp; &nbsp;Submit</span></button>
                            </div> */}

                        {/* <div className="float-right z-[100] relative rounded bg-white w-28 h-10">
                            <div className="z-50 bg-white rounded-lg w-full h-full border-white">
                            </div>
                            <button className={'border-2 z-[60] focus:outline-none bg-black text-pinkshade font-Krona text-sm absolute -top-3 transform translate-x-1.5 translate-y-1.5 -left-3 w-full h-full border-black rounded'}
                            >Submit</button>
                        </div> */}
                    </form>
                </div>
            </div>
           <Footer />
        </div>
    )
}

export default ContactUs