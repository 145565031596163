import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NavModal from './components/navModal'
import Home from './components/home'
import GoodEvil from './components/goodEvil'
import Temple from './components/temple'
import './App.css'
import icon from './assets/icon.svg'
import Games from './components/games'
import WorldsWeBuild from './components/worldsWeBuild'
import AboutUs from './components/aboutUs/aboutUs';
import GameLib from './components/gameLib/gameLib';
import ContactUs from './components/contactUs/contactUs';

export default function Routes() {
    const [isNavVisisble, setIsNavVisible] = useState(false)
    const [currentRoute, setCurrentRoute] = useState('home')
    const [isFirst,setIsFirst]=useState(true);
    return (
        <Switch>
            <Route exact path="/" ><div className="App  h-[100vh] w-[100vw] ">
                <NavModal isNavVisisble={isNavVisisble} setCurrentRoute={setCurrentRoute} currentRoute={currentRoute} setIsNavVisible={setIsNavVisible} />
                <Home isFirst={isFirst} setIsFirst={setIsFirst} isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} />
                {/* <GoodEvil isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} /> */}
                {/* <Temple isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} /> */}
                {/* <Games isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} />
                <WorldsWeBuild isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} /> */}
            </div></Route>
            <Route path="/aboutus" >
                <NavModal isNavVisisble={isNavVisisble} setCurrentRoute={setCurrentRoute} currentRoute={currentRoute} setIsNavVisible={setIsNavVisible} />
                <AboutUs isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} />
            </Route>
            <Route path="/gamelib" >
                <NavModal isNavVisisble={isNavVisisble} setCurrentRoute={setCurrentRoute} currentRoute={currentRoute} setIsNavVisible={setIsNavVisible} />
                <GameLib isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} />
            </Route>
            <Route path="/contactus">
                <NavModal isNavVisisble={isNavVisisble} setCurrentRoute={setCurrentRoute} currentRoute={currentRoute} setIsNavVisible={setIsNavVisible} />
                <ContactUs isNavVisisble={isNavVisisble} setIsNavVisible={setIsNavVisible} /></Route>
        </Switch>
    )
}