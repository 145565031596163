import React, { Fragment } from 'react'
import icon from '../../assets/icon.svg'
import footerbottom from '../../assets/footer-bottom.png'
import { IoLocationOutline, IoMailOutline } from 'react-icons/io5';
import {  Link } from 'react-router-dom';
import Footer from '../Footer';

function GameLib({ isNavVisisble, setIsNavVisible }) {
    return (
        <div className="worldswebuild bg-[length:100vw_100vh] bg-no-repeat bg-bermuda  h-[100vh] flex flex-col">
            <div className="h-24 w-20  mx-5 bg-black  text-white z-10 fixed top-0 left-8 flex flex-col  ">
                <div className='flex-1 flex items-center justify-center'>
                <Link to="/">   <img src='/Illustrix_Icon.png' /></Link>
                </div>
                <div className='flex items-center justify-center font-Krona'>
                    Illustrix
                </div>
            </div>
            <div className='bg-red fixed right-6 top-3   text-red-700'>
                <img className='hover:cursor-pointer' src={icon} onClick={() => { setIsNavVisible(true) }} />
            </div>
            <div className='h-[70%]'>
                <div className='mt-[18vh] flex w-full items-center text-white justify-center overflow-y-hidden font-Krona text-6xl'>
                    Coming <br/>Soon...<br/>Stay<br/>tuned...
                </div>
                {/* <div className="ml-16 mt-6 flex w-1/2 leading-[1.5rem] items-center text-white justify-left font-Krona text-base">
                    We are the new age creators of worlds for you to be the gods in.<br />
                    Illustrix is a next generation community driven game development company <br /> which focuses on developing FPS,
                    story-based and open world games across<br /> platforms such as XBOX, PS, PC and virtual reality. We believe gaming
                    is the way <br />people are going to interact with each other and we want to be the <br />catalyst for the same.
                    Using upcoming technologies such as VR, AR, MR for <br />interaction and 5g for faster communication, AI for
                    better game-player <br />understanding we are committed in making our games more interactive and <br />engaging for
                    our players. Illustrix also aims in promoting and developing <br />games for medical benefits along with the engaging and entertaining games.
                </div> */}
            </div>
            <Footer />
        </div>
    )
}

export default GameLib