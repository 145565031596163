import React,{useEffect} from 'react'
import headericon from '../../assets/loadericon.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

function LoadingScreen() {
  useEffect(() => {
      AOS.init({
        duration: 1500,
      });
  }, [])
    return (
        <div className='min-h-screen flex flex-col items-center justify-center loading-animation' style={{background:"#0F0F0F"}}>
          <img data-aos="fade-in" src={headericon} alt="" />
          <div data-aos="fade-in" data-aos-delay="1000" className='mt-2 text-4xl text-white font-Krona overflow-y-hidden text-center'>Illustrix</div>
          <div data-aos="fade-in" data-aos-delay="2000" className='mt-2 ml-5 text-xl text-white font-Krona text-center'>World's we build,<br/>God's you become</div>
        </div>
    )
}

export default LoadingScreen