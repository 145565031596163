import React, { Fragment, useState, useEffect } from 'react'
import icon from '../../assets/icon.svg';
import xbox from '../../assets/xbox.png';
import meta from '../../assets/meta.png';
import microsoft from '../../assets/microsoft.png';
import vive from '../../assets/vive.png';
import playstation from '../../assets/playstation.png';
import LoadingScreen from '../loadingScreen/loadingScreen';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import Footer from '../Footer';
function Home({ setIsFirst, isFirst, isNavVisisble, setIsNavVisible }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Aos.init();
    setTimeout(() => {
      setLoading(false)
      setIsFirst(false);
    }, 3000);
  }, [])

  useEffect(() => {
    document.addEventListener("mousemove", parallax);
    function parallax(event) {
      this.querySelectorAll(".world").forEach((shift) => {
        const x = (window.innerWidth - event.pageX - 200) / 100;
        const y = (window.innerHeight - event.pageY - 200) / 100;
        shift.style.backgroundPosition = `${50 + x}% ${50 + y}%`
      });
    }
  }, [])

  // if (loading && isFirst) return <LoadingScreen />
  return (
    <>
      {(loading && isFirst) && <LoadingScreen />}
      <div className='bg-black'>
        <div className='cont_'>
          <div className='section' >
            <div data-aos="fade-in" className="animate-loadingAnimation world bg-no-repeat bg-cover h-[100vh] flex items-end pb-5 pl-5">
              <div className="h-24 w-20  mx-5 bg-[#0A0A0A] text-white z-10 fixed top-0 left-8 flex flex-col  ">
                <div className='flex-1 flex items-center justify-center'>
                  <Link to="/">   <img src='/Illustrix_Icon.png' alt='' className='filter drop-shadow-xl shadow-violet-800' /></Link>
                </div>
                <div className='flex items-center justify-center font-Krona' style={{ textShadow: '1px 1px 3px #000' }}>
                  Illustrix
                </div>
              </div>
              <div className='bg-red fixed right-6 top-3 z-50'>
                <img className='hover:cursor-pointer' alt='' src={icon} onClick={() => { setIsNavVisible(true) }} />
              </div>
              <div className='text-white bottom-10 left-5 font-Krona text-3xl'>
                Step Into The Worlds Waiting <br /> to be  Discovered
                <div className='h-[2px] w-[43%] bg-white'></div>
              </div>
            </div>
          </div>

          <div className='section'>
            <div className="animate-scale home bg-no-repeat bg-cover h-[100vh] flex items-end pb-5 pl-5">
              <div className='relative'>
                <div className='text-white bottom-10 left-5 font-Krona text-3xl'>
                  Unmatched Gameplay
                  <div className='h-[2px] w-[60%] bg-white'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='section' >
            <div className="pulse-animation temple bg-no-repeat bg-cover h-[100vh] flex items-end pb-5 pl-5">
              <div className='relative'>
                <div data-aos-delay="1000" className='text-white  bottom-10 left-5 font-Krona  text-3xl  drop-shadow-lg shadow-black'>
                  Story Telling Like <br /> No Other
                  <div data-aos-delay="500" className='h-[2px] w-[72%] bg-white'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='section'>
            <div className="games bg-no-repeat bg-cover w-full h-[100vh] flex flex-col items-center justify-center gap-8">
              <div className='w-full font-Krona overflow-y-hidden line-height letter-spacing text-center font-semibold text-white text-7xl drop-shadow-lg shadow-black'>
                GAMES FOR ALL<br /> PLATFORM</div>
              <div className='w-full items-center justify-center gap-12 flex flex-row icon_tray'>
                <img src={xbox} className=" w-16 h-16" alt='' />
                <img src={meta} className=" w-20 h-16" alt='' />
                <img src={playstation} className="w-16 h-16" alt='' />
                <img src={microsoft} className=" w-16 h-16" alt='' />
                <img src={vive} className=" w-20 h-16" alt='' />
              </div>
            </div>
          </div>
          <div className='section'>
            <div className="worldswebuild bg-[length:100vw_100vh] bg-no-repeat bg-bermuda  h-[100vh] flex flex-col">
              <div className='flex h-[70%] w-full leading-[5.4rem] items-center text-white justify-center font-Krona text-7xl'>
                Worlds We <br /> Build,<br /> Gods You <br />Become...
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home




export const useScriptURL = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};
export const useScript = content => {
  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = content;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [content]);
};
