import React, { useEffect } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useHistory, useLocation } from 'react-router-dom';

function NavModal({ setCurrentRoute, currentRoute, isNavVisisble, setIsNavVisible }) {
  const history = useHistory()
  const location = useLocation()
  function homeRoute() {
    history.push('/')
    setIsNavVisible(false)
    setCurrentRoute('home')
  }
  function aboutUsRoute() {
    history.push('/aboutus')
    setIsNavVisible(false)
    setCurrentRoute('aboutus')
  }
  function contactUsRoute() {
    history.push('/contactus')
    setIsNavVisible(false)
    setCurrentRoute('contactus')
  }
  function gameLibRoute() {
    history.push('/gamelib')
    setIsNavVisible(false)
    setCurrentRoute('gamelib')
  }


  if (isNavVisisble) {
    return <div className='z-[70] animate-navFadeIn overflow-hidden bg-bgblackshade fixed top-0 left-0 h-[100vh] w-[100vw] text-white' style={{background: `#101010`}}>
      <div className='p-5'>
        <AiOutlineClose size={32} onClick={() => { setIsNavVisible(!isNavVisisble) }} className="animate-navFadeOut hover:cursor-pointer" />
      </div>
      <div className=' z-[100] overflow-visible absolute -right-[360px] top-1/2 transform -translate-y-1/2 flex items-center justify-start h-[720px] w-[720px] rounded-full'>

        <div className='text-violet overflow-visible flex flex-col w-full h-full border-2 rounded-full  border-violet border-solid'>
          <div onClick={contactUsRoute} className={`flex-1 flex text-white hover:text-violet  parent hover:cursor-pointer items-center gap-5 font-Krona ${currentRoute == "contactus" ? 'clicked text-4xl ml-[-240px]' : "text-2xl ml-[-136px]"}`}><span className={`align-text-bottom overflow-hidden`}>01</span>Contact us<div className={`${currentRoute == 'contactus' ? 'bg-violet' : 'child'} w-5 h-5 rounded-full`}></div></div>
          <div onClick={homeRoute} className={`flex-1 flex text-white hover:text-violet  parent hover:cursor-pointer  items-center font-Krona gap-3 ${currentRoute == "home" ? "clicked text-4xl ml-[-230px]" : "text-2xl ml-[-156px]"}`}><span className={`overflow-hidden`}>02 Home </span>  <div className={`${currentRoute == 'home' ? 'bg-violet' : 'child'} w-5 h-5 rounded-full`}></div></div>
          <div onClick={gameLibRoute} className={`flex-1 flex text-white hover:text-violet parent hover:cursor-pointer   items-center font-Krona -ml-44 gap-6 ${currentRoute == "gamelib" ? "clicked text-4xl ml-[-274px]" : "text-2xl ml-[-198px]"}`}><span className={`align-text-bottom overflow-hidden`}>03</span>Game <br />Library <div className={`${currentRoute == 'gamelib' ? 'bg-violet' : 'child'} w-5 h-5 rounded-full`}></div></div>
          <div onClick={aboutUsRoute} className={`flex-1 flex text-white hover:text-violet  parent hover:cursor-pointer items-center font-Krona -ml-20 gap-4 ${currentRoute == "aboutus" ? "clicked text-4xl ml-[-198px]" : "text-2xl ml-[-104px]"}`}><span className={`align-text-bottom overflow-hidden`}>04</span>About us <div className={`${currentRoute == 'aboutus' ? 'bg-violet' : 'child'} w-5 h-5 rounded-full`}></div></div>
        </div>
      </div>


    </div>

  }

}

export default NavModal