import React from 'react'
import footerbottom from '../assets/footer-bottom.png'
import { IoLocationOutline, IoMailOutline } from 'react-icons/io5'


function Footer() {
    return (
        <>
            <footer className=''>
                <div className='flex w-full items-center justify-start '>
                    <div className=" h-full flex-1 text-white items-center justify-center text-start mx-5 ">

                        <li className='ml-12 font-Poppins text-xl w-auto font-thin list-none mb-5'>
                            Threadality<br /> Technology LLC
                        </li>

                        <li className='ml-12 list-none'>
                            Illustrix is an animation and <br /> gaming company by Threadality
                        </li>
                    </div>
                    <div className=" h-full flex-1 list-none   text-white  text-start mx-5">

                        <li className='mb-5'>Navigate to</li>
                        <li className='mb-2  hover:cursor-pointer hover:underline'>Home</li>
                        <li className='mb-2 hover:cursor-pointer hover:underline'>About Us</li>
                        <li className=' hover:cursor-pointer hover:underline'> Contact Us</li>

                    </div>
                    <div className=" h-full list-none flex-[2] text-white items-center justify-center  text-start mx-10">

                        <li className='mb-5'>Get in Touch</li>
                        <li className='mb-2 text-sm hover:cursor-pointer hover:underline flex flex-row items-start justify-start gap-3'><IoLocationOutline fontSize={24} /> Business Bay Area, Dubai, <br />Dubai, UAE</li>
                        <li className='mb-2 text-sm hover:cursor-pointer hover:underline flex flex-row items-start justify-start gap-3'><IoMailOutline fontSize={24} /> hello@illustrix.gg</li>

                    </div>

                    <a href='https://morphline.studio/' target="_blank" rel="noreferrer"><img src={footerbottom} className="w-28 mr-3 cursor-pointer" /></a>
                </div>
                <div className='ml-16 flex py-6 w-full text-white items-center justify-start '>
                    ©Threadaity Technologies
                </div>
            </footer>
        </>
    )
}

export default Footer